let resumeData = {
    "imagebaseurl":"",
    "name": "Mohd Faraz",
    "role": "Fullstack Developer ",
    "resumetext":"You can download/view my resume from here",
    "linkedinId":"https://www.linkedin.com/in/mohd-faraz",
    "skypeid": "Your skypeid",
    "resumeurl":"https://drive.google.com/file/d/1lI0-WKktx4JIinJ_wV-oqyr5J2E0vvmF/view?usp=sharing",
    "roleDescription": "I like dabbling in various parts of fullstack development and like to learn about new technologies, write technical articles or simply play games in my free time.",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/mohd-faraz",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"https://github.com/fzmalik",
          "className":"fa fa-github"
        },
        {
          "name":"twitter",
          "url":"https://twitter.com/MohdFaraz796189",
          "className":"fa fa-twitter"
        },
        {
          "name":"hackerrank",
          "url":"https://www.hackerrank.com/mohdfarazmalik71",
          "className":"lab la-hackerrank"
        }
      ],
    "aboutme":"I am currently a pre-final year student at The University Institute of Engineering and Technology Kanpur and pursuing my B.Tech from here. I am a self taught Full Stack Web Developer, currently diving deeper into Data Structure and Algorithm. I believe that to be successful in life, one needs to be obsessive with their dreams and keep working towards them.",
    "address":"India",
    "website":"https://mohdfaraz.xyz",
    "education":[
      {
        "UniversityName":"University Institute of Engineering and Technology",
        "specialization":"Information Technology",
        "MonthOfPassing":"May",
        "YearOfPassing":"2023",
        "Achievements":""
      },
      // {
      //   "UniversityName":"Some University",
      //   "specialization":"Some specialization",
      //   "MonthOfPassing":"Jan",
      //   "YearOfPassing":"2018",
      //   "Achievements":"Some Achievements"
      // }
    ],
    "work":[
      {
        "CompanyName":"Classplus",
        "specialization":"Software Engineering Intern",
        "DateOfStarting":"June 2022",
        "DateOfLeaving":"August 2022",
        "Achievements":""
      },
      // {
      //   "CompanyName":"Classplus",
      //   "specialization":"Software Engineering Intern",
      //   "DateOfStarting":"June 2022",
      //   "DateOfLeaving":"August 2022",
      //   "Achievements":"Some Achievements"
      // }
      
    ],
    "skillsDescription":"My skills are here",
    "skills":[
      {
        "skillname":"HTML5",
        "rating":9
      },
      {
        "skillname":"CSS",
        "rating":8
      },
      {
        "skillname":"Reactjs",
        "rating":5
      },
      {
        "skillname":"Debugging Code",
        "rating":7
      },
      {
        "skillname":"Back-end Operations",
        "rating":8
      },
      {
        "skillname":"npm",
        "rating":8
      },
      {
        "skillname":"JavaScript",
        "rating":9
      },
      {
        "skillname":"Java",
        "rating":7
      },
      {
        "skillname":"C++",
        "rating":6
      },
      {
        "skillname":"C (Programming Language)",
        "rating":6
      },
      {
        "skillname":"Python (Programming Language)",
        "rating":5
      },
      {
        "skillname":"SQL",
        "rating":8.5
      },
      {
        "skillname":"Node.js",
        "rating":9
      },
      {
        "skillname":"Express.js",
        "rating":10
      },
      {
        "skillname":"MongoDB",
        "rating":8
      },
      {
        "skillname":"CRUD",
        "rating":10
      },
      {
        "skillname":"Git",
        "rating":9
      },
      {
        "skillname":"GitHub",
        "rating":9
      },
      {
        "skillname":"Git BASH",
        "rating":8
      },
      {
        "skillname":"OOP's",
        "rating":7
      },
      {
        "skillname":"Data Structure",
        "rating":8
      },
      {
        "skillname":"MySQL",
        "rating":9
      },
      {
        "skillname":"REST APIs",
        "rating":7
      },
    ],
    "portfolio":[
      {
        "name":"Carserol",
        "description":"webpage",
        "imgurl":"images/portfolio/phone.jpg",
        "url":"https://fzmalik.github.io/Frontend-assignment-is/"
      },
      {
        "name":"portfolio web page",
        "description":"web",
        "imgurl":"images/portfolio/project.jpg",
        "url":"https://fzmalik.github.io/mywebsite/"
      },
      {
        "name":"CV Web page",
        "description":"web",  
        "imgurl":"images/portfolio/project2.jpg",
        "url":"https://fzmalik.github.io/mycv/"
      },
      {
        "name":"A furniture web site using php,html and css.",
        "description":"web",
        "imgurl":"images/portfolio/phone1.jpg",
        "url":"https://fzmalik.github.io/furniture/Contacus.html"
      },
      {
        "name":"Fent end practice web site",
        "description":"web",
        "imgurl":"images/portfolio/project5.jpg",
        "url":"https://fzmalik.github.io/tindogfz/#"
      }

    ],
    "testimonials":[
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      },
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      }
    ]
  }
  
  export default resumeData