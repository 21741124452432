import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast } from 'react-toastify';
import { SpinnerInfinity } from 'spinners-react';

// import { BASE_URL } from '../constant';
 class signUp extends Component {
     constructor(props) {
       super(props)
     
       this.state = {
          isLogedin :false,
          isLoading: false,
          message: [],
          queryString :'',
          userName : '',
          pwd:'',
          name:'',
          mobile:'',

          // apiKey:'3f80deeb8d777bcf7da6e4ca5af209cf12feea1b95b9a19d46f242a3'
       }
       this.handleChangeUname = this.handleChangeUname.bind(this);
       this.handleChangeMobile = this.handleChangeMobile.bind(this);
       this.handleChangeName = this.handleChangeName.bind(this);
       this.handleChangePwd = this.handleChangePwd.bind(this);
       this.handleSubmite = this.handleSubmite.bind(this);
     }
     handleChangeUname({ target }) {
      this.setState({
        userName: target.value
      });
    }
    handleChangeName({ target }) {
      this.setState({
        name: target.value
      });
    }
    handleChangePwd({ target }) {
      this.setState({
        pwd: target.value
      });
    }
    handleChangeMobile({ target }) {
      this.setState({
        mobile: target.value
      });
      
    }
    handleSubmite({target})
    {
      const emailREGEX = /(\W|^)[\w.+\-]*@gmail\.com(\W|$)/;
      const passwordREGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[@,#,$,%,&,^,*]).{8,}.$/;
      const mobileREGEX = /^[0-9]{10}$/;
      var raw = JSON.stringify({
        "email": this.state.userName,
        "password":this.state.pwd,
        'name':this.state.name,
        'mobile': this.state.mobile
        // userIp:data.ip,
       //  userData: data,
      });
      
      var requestOptions = {
        method: 'POST',
        headers: 
        {
         "Content-Type": "application/json"
        },
        body: raw,
        redirect: 'follow'
      };
      if(!emailREGEX.test(this.state.userName) || !passwordREGEX.test(this.state.pwd) || !mobileREGEX.test(this.state.mobile) )
      {
        if(!emailREGEX.test(this.state.userName))
        {
          toast.info("Please Enter a valid email id",{position: "top-center"});
        }
        if(!passwordREGEX.test(this.state.pwd))
        {
          toast.info("Password must contain at least 8 characters,must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number,Can contain special characters",{position: "top-center"});
        }
        if(!mobileREGEX.test(this.state.mobile))
        {
          toast.info("Mobile Number Should be of 10 digits",{position: "top-center"});
        }
      }
      else
      {
        this.setState({
          isLoading:true
        })

        fetch(`${process.env.REACT_APP_BASE_URL}user/createuser`, requestOptions)
        .then(response => response.json())
        .then(result1 => {
        //  alert(result1.data[0].token)
        //  console.log(result1)

        if(result1.status === 'success')
        {
         toast.success(result1.message);
         setTimeout(function() {
        
          window.location.reload()

        }, 1000);
        }
    
         else if(result1.status === 'failure')
        {
          // const notify = () => toast("Wow so easy!");
          // alert("ssss")
          toast.warn(result1.message);
        }
        this.setState({
          isLoading:false
        })
          
        })
      }
      
       
      


      //  alert(this.state.userName);
      // alert(this.state.pwd);
      

    }


  render() {
    const refreshPage = ()=>{
      window.location.reload();
   }
   if(this.state.isLoading)
   {
    return(
      <div id="loginDiv" style={{  }}>
        <SpinnerInfinity className='spinner' enabled={true} size = '20%' thickness={50}/>
      </div>
    )
   }
   else
   {
    return(
            <div id="loginDiv" style={{  }}>
     <form>
       <div className="input-container">
          <label>Name</label>
         <input type="text" value ={ this.state.name } onChange={ this.handleChangeName } name="name" required />
         <label>Email</label>
         <input type="text" value ={ this.state.userName } onChange={ this.handleChangeUname } name="uname" required />
         <label>Mobile Number</label>
         <input type="text" value ={ this.state.mobile } onChange={ this.handleChangeMobile } name="mobile" required />
       </div>
       <div className="input-container">
         <label>Password </label>
         <input type="password" value ={ this.state.pwd } onChange={ this.handleChangePwd } name="pass" required />
       </div>
       <div className="button-container">
       <Button type='submit' id='submitButton' onClick={this.handleSubmite} variant="outline-primary" size="lg"><Link to={'/'} className="nav-link" >SignUp</Link></Button>
       </div>
     </form>
     <ToastContainer />
      <label onClick={refreshPage} ><Link to={'/login'} className="nav-link" >Login</Link></label>
   </div>
          )
      }
    }
  }

export default signUp;
