import React, { Component } from 'react';
import resumeData from '../resumeData';
import About from './About';
import Header from './Header';
import Resume from './Resume';
import Portfolio from './Portfolio';
import Testimonials from './Testimonials';
import ContactUs from './ContactUs';
import Footer from './Footer';
class portfolioHome extends Component {
  render() {
    return (
      <div className="PortfolioHome">
        <Header resumeData={resumeData}/>
         <About resumeData={resumeData}/>
         <Resume resumeData={resumeData}/>
         <Portfolio resumeData={resumeData}/> 
         {/* <Portfolio resumeData={resumeData}/>  */}
          <Testimonials resumeData={resumeData}/>
          <ContactUs resumeData={resumeData}/>
          <Footer resumeData={resumeData}/>
      </div>
    );
  }
}

export default portfolioHome;