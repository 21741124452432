import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
// import { Router} from "react-router";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SpinnerInfinity } from 'spinners-react';

// import { BASE_URL } from '../constant';
 class Login extends Component {
     constructor(props) {
       super(props)
     
       this.state = {
          isLogedin :false,
          isLoading: false,
          message: [],
          userName :'',
          pwd:'',
          // apiKey:'3f80deeb8d777bcf7da6e4ca5af209cf12feea1b95b9a19d46f242a3'
       }
       this.handleChangeUname = this.handleChangeUname.bind(this);
       this.handleChangePwd = this.handleChangePwd.bind(this);
       this.handleSubmite = this.handleSubmite.bind(this);

     }

     handleChangeUname({ target }) {
      this.setState({
        userName: target.value
      });
    }
    handleChangePwd({ target }) {
      this.setState({
        pwd: target.value
      });
    }
    handleSubmite({target})
    {
      var raw = JSON.stringify({
        "email": this.state.userName,
        "password":this.state.pwd,
        // userIp:data.ip,
       //  userData: data,
      });
      
      var requestOptions = {
        method: 'POST',
        headers: 
        {
         "Content-Type": "application/json",
         "x-access-token": localStorage.getItem('x-access-token')||'',
         "type":"login"
        },
        body: raw,
        redirect: 'follow'
      };
      this.setState({
        isLoading:true
      })
      fetch(`${process.env.REACT_APP_BASE_URL}user/login`, requestOptions)
      .then(response => response.json())
      .then(result1 => {
      //  alert(JSON.stringify(result1.data[0]))
      //  console.log(result1)
       localStorage.setItem("x-access-token",result1.data[0].token||'');
       localStorage.setItem("visibility",result1.data[0].userValidity||0);
       localStorage.setItem("accessControl",result1.data[0].accessControl||0);
       localStorage.setItem("isLogin",result1.data[0].isLogin||0);
       

       if(result1.status === 'success')
       {
        toast.success('Successfully Login');
        <Link to={'/'} className="nav-link" ></Link>
        this.props.getUserDetails();
        setTimeout(function() {
        
          window.location.reload()
  
        }, 1500);
       }
    
       else if(result1.status === 'failure')
       {
        // const notify = () => toast("Wow so easy!");
        // alert("ssss")
        toast.warn(result1.message);
        
       }
       this.setState({
        isLoading:false
      })
       
      })
       
     


      //  alert(this.state.userName);
      // alert(this.state.pwd);
      

    }

  render() {
    const refreshPage = ()=>{
      window.location.reload();
   }
   if(this.state.isLoading)
   {
    return(
      <div id="loginDiv" style={{  }}>
        <SpinnerInfinity className='spinner' enabled={true} size = '20%' thickness={50}/>
      </div>
    )
   }
   else
   {
    return(
      <div id="loginDiv" style={{  }}>
 <div className="input-container">
   <label>Username</label>
   <input type="text" value ={ this.state.userName } onChange={ this.handleChangeUname } name="uname" required />
 </div>
 <div className="input-container">
   <label>Password </label>
   <input type="password" value ={ this.state.pwd } onChange={ this.handleChangePwd } name="pass" required />
 </div>
 <div className="button-container">
  <Button type='submit' id='submitButton' onClick={this.handleSubmite} variant="outline-primary" size="lg">Login</Button>
  <ToastContainer />
  <label onClick={refreshPage} ><Link to={'/SignUp'} className="nav-link" >SignUp</Link></label>
 </div>
 


</div>
    )
   }
      }
  }

export default Login
