import React, { Component } from 'react'
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
// import { BASE_URL } from '../constant';
 class Home extends Component {
     constructor(props) {
       super(props)
     
       this.state = {
          isLogedin :false,
          isLoading: false,
          message: [],
          queryString :'',
          topicBox:'',
          // apiKey:'3f80deeb8d777bcf7da6e4ca5af209cf12feea1b95b9a19d46f242a3'
       }

     }
  render() {
          return(
            <div className="home123">
                <div className="banner-text">
                    <h2 id="welcome">Hello and welcome to our website. We are glad you are here and hope you find what you are looking for. If you have any questions, please feel free to contact us. Thank you for visiting!</h2>
                    <br/>
                    <br/>
                    <h3>A smile is the universal welcome</h3>
                    <h4>- Max Eastman</h4>
                    <h3>The world is a book, and those who do not travel read only one page.</h3> 
                    <h4>- Saint Augustine</h4>
                    <h3>Fullstack: the idea that you can't understand one piece of the technology stack without having a model of the other pieces.</h3>
                    <h4>- Alan Kay</h4>
                    <hr/>
                </div>
            </div>
          )
      }
  }

export default Home
