//import logo from './logo.svg';
import './App.css';
//import Parent from './Component/Parent';
import DataSharing from './Component/DataSharing';

// import { ToastContainer, toast } from 'react-toastify';
// import coding from './Component/coding';
// import map from './Component/map'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import React, { Component } from 'react';
import portfolioHome from './Component/portfolioHome';
import Login from './Component/login';
import Home from './Component/Home';
import signUp from './Component/signUp';
import UserAccessControl from './Component/accessControll';

class App extends Component{

  constructor(props) {
    super(props)
  
    this.state = {
       isLogedin :false,
       isLoading: false,
      //  data:[]

       // apiKey:'3f80deeb8d777bcf7da6e4ca5af209cf12feea1b95b9a19d46f242a3'
    }
    this.getUserDetails = this.getUserDetails.bind(this);
  }
  getData = () =>{

    fetch(`${process.env.REACT_APP_BASE_URL}data/getData`, {
      method: 'GET',
      mode: "cors",
      headers: {
         'Content-type': 'application/json; charset=UTF-8',
         'x-access-token':localStorage.getItem("x-access-token"),
         'access-control-allow-credentials': true,
         'access-control-allow-headers': 'Api-Version,DNT,X-CustomHeader,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,X-Access-Token,device-id,tutorWebsiteDomain,region,accept-language,X-Forwarded-For,x-chrome-version,x-webview-version,zAccessToken,zRefreshToken,Authorization',
         'access-control-allow-methods': 'POST',
         'Access-Control-Allow-Origin': '*',
         'access-control-max-age': 1728000,

      },
   }).then(response => response.json())
   .then((response) => {
      //  console.log("ddddddddddddddd",response.status)
        if(response.status == 'success')
        {
         //  toast.success(response.message);
          localStorage.setItem("sentData",JSON.stringify(response.data[0].sendData)||[]);
          localStorage.setItem("recievedData",JSON.stringify(response.data[0].recieveData)||[]);

        }
        else
        {
         //  toast.warn(response.message);
        } 
        setTimeout(function() {
      
          window.location.reload()
  
        }, 100);
        
  })
}
  getUserList = () =>{
          
    var requestOptions = {
      method: 'GET',
      headers: 
      {
       "Content-Type": "application/json",
       "x-access-token":localStorage.getItem("x-access-token")
      },
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_BASE_URL}user/getUsers`, requestOptions)
.then(response => response.json())
.then(result => {
// this.setState({data:result.data[0].usersData});
localStorage.setItem("usersData",JSON.stringify(result.data[0].usersData));

if(result.status === 'success')
     {
      // alert(JSON.stringify(result1.data[0].name))
     }
     setTimeout(function() {
      
      window.location.reload()
    
    }, 100);
// console.log(result)
})
.catch(error => console.log('error', error));
} 


  getUserDetails()
  { 
    var requestOptions = {
      method: 'GET',
      headers: 
      {
       "Content-Type": "application/json",
       "x-access-token":localStorage.getItem('x-access-token'),
       "type":'login'
      },
      redirect: 'follow'
    };
    fetch(`${process.env.REACT_APP_BASE_URL}user/userDetails`, requestOptions)
    .then(response => response.json())
    .then(result1 => {
    //  alert(result1.data[0].token)
    //  console.log(result1)

     if(result1.status === 'success')
     {
      // alert(JSON.stringify(result1.data[0].name))
      
      localStorage.setItem('profileName',result1.data[0].name);
      localStorage.setItem('visibility',result1.data[0].userValidity)
     }
  
     else if(result1.status === 'failure')
     {
      // const notify = () => toast("Wow so easy!");
      // alert("ssss")
      // toast.warn(result1.message);
      // alert(result1.message)
      console.error("error for userDetail:",result1.message);
     }
      setTimeout(function() {
      
        window.location.reload()

      }, 100);
    })
     
    


    //  alert(this.state.userName);
    // alert(this.state.pwd);
    

  }
 render() { 
  const refreshPage = ()=>{
   
    window.location.reload();
 }

 const visibilityOfAccessControlTab = () =>
 {
  if(localStorage.getItem('accessControl') == 1){

    return(<>
    <li onClick={this.getUserList}><Link to={'/usersAccess'} className="nav-link">Users Access Controller</Link></li></>
    );          
  }
 }

 const AccessControlLink = () =>
 {
  if(localStorage.getItem('accessControl') == 1){

    return(
    <Route exact path='/usersAccess' component={()=>(<UserAccessControl getUserList = {this.getUserList} />)} />
    );          
  }
 }
 const loginCheck =() =>{
   if(localStorage.getItem('isLogin') == 1)
   {
    return (<><li onClick={logOut}><Link to={'/'} className="nav-link"> logOut </Link></li>
    <li onClick={refreshPage}><Link to={'/portfolio'} className="nav-link"><i className="las la-user"></i>{localStorage.getItem('profileName')}</Link></li></>);
   }
   else
   {
    return(<li onClick={refreshPage}><Link to={'/login'} className="nav-link" >Login</Link></li>)
   }
 }
 const visibilityOfQuetstionTab = ()=>
 {
  if(localStorage.visibility == 1){

    return(<><li onClick={this.getData}><Link to={'/DataSharing'} className="nav-link"> Data Sharing </Link></li></>
    );          
  }
}
 const visibilityLinking = () =>
 {
  if(localStorage.visibility == 1){
    return(<Route exact path='/DataSharing' component={DataSharing} />);
  }
  else
  {
    return(<Route path='/login' component={()=>(<Login getUserDetails = {this.getUserDetails} />)} />);
  }
 }
 const logOut = () =>
 {
  localStorage.clear()
  refreshPage()
 }
  return (
    <Router >
        <div className="App">
          <nav  className="navbar navbar-expand-lg navbar-light bg-dark">
          {/* <h2>Welcome to Malik Group</h2> */}
          <ul id = "nav" className="homeNaveBar navbar-nav ml-auto">

          <li onClick={this.getUserDetails}><Link to={'/'} className="nav-link" >Home</Link></li>
          <li onClick={refreshPage}><Link to={'/portfolio'} className="nav-link" >Portfolio</Link></li>
          {visibilityOfAccessControlTab()}
          {visibilityOfQuetstionTab()}
          {loginCheck()}
            {/*<li onClick={refreshPage}><Link to={'/coding'} className="nav-link" >Coding Specific Question</Link></li>*/}
            {/* <li onClick={refreshPage}><Link to={'/map'} className="nav-link" >map</Link></li> */}
            
            
            

         
          </ul>
          </nav>
          <hr />
          <Switch>
              {visibilityLinking()}
              {AccessControlLink()}
              {/*<Route path='/coding' component={coding} />*/}
              {/* <Route path='/map' component={map} /> */}
              <Route path='/portfolio' component={portfolioHome} />
              <Route path='/SignUp' component={signUp} />
              <Route path='/' component={Home} />

          </Switch>
          {/* <ToastContainer /> */}
        </div>
      </Router>
    // <div className="App">
    //   <DataSharing />
    // {/* <Parent/>*/}
    // </div>
  );
}
}

export default App;
