import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Document, Page } from 'react-pdf';
import {useState} from 'react';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height:'90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal(props) {
  let [isPDFViewerModalOpen,setisPDFViewerModalOpen] = useState(false)
  let [fileurl,setfileurl] = useState(null)
   function handlePDFViewModal(e){
    
    if(!isPDFViewerModalOpen)
    {
      getFile()   
    }
    else
    {
      setisPDFViewerModalOpen(!isPDFViewerModalOpen)
    }
    }
   const getFile = () =>{

        // alert(e.target.id)
          fetch(`${process.env.REACT_APP_BASE_URL}data/Download/?id=${props.fileid}`, {
            method: 'GET',
            mode: "cors",
            // body:{id:e.target.id},
            headers: {
               'Content-type': 'application/json; charset=UTF-8',
               'x-access-token':localStorage.getItem("x-access-token"),
               'access-control-allow-credentials': true,
               'access-control-allow-headers': 'Api-Version,DNT,X-CustomHeader,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,X-Access-Token,device-id,tutorWebsiteDomain,region,accept-language,X-Forwarded-For,x-chrome-version,x-webview-version,zAccessToken,zRefreshToken,Authorization',
               'access-control-allow-methods': 'POST',
               'Access-Control-Allow-Origin': '*',
               'access-control-max-age': 1728000,
    
            },
         }).then(response => response.json())
         .then((response) => {
            //  console.log("ddddddddddddddd",response.status)
              if(response.status == 'success')
              {
                // new File(response.data[0].downloadData,response.data[0].name)
                let reader = new FileReader();
                // reader.readAsDataURL(response.data[0].downloadData)
                let href = response.data[0].downloadData;
                // alert(href)
                setfileurl(href)
                setisPDFViewerModalOpen(!isPDFViewerModalOpen)
              }
              else
              {
                // toast.warn(response.message);
              } 
              
        })
     }

  return (
    <div className='PDFViewerDiv'>
      <Button variant="contained" onClick={props.getFile}><h5 id={props.fileid}>Click Download</h5></Button>
      <Button variant="contained" onClick={handlePDFViewModal}><h5>Click To View</h5></Button>
      <Modal
        open={isPDFViewerModalOpen}
        onClose={handlePDFViewModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
                <h1>{props.name}</h1>
                <embed src={fileurl} width='100%' height='90%' type='application/pdf'></embed>
        </Box>
      </Modal>
    </div>
  );
}