import React, { Component } from 'react'
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import { toast, ToastContainer } from 'react-toastify';
import { InfinitySpin } from 'react-loader-spinner';
import { SpinnerInfinity } from 'spinners-react';
import Example from "./PDFViewer";
// import { BASE_URL } from '../constant';
 class DataSharing extends Component {
     constructor(props) {
       super(props)
     
       this.state = {
          isLogedin :false,
          isLoading: false,
          message: [],
          dataString :'',
          topicBox:'',
          reciever:'',
          fileTable:null,
          first:true,
          // apiKey:'3f80deeb8d777bcf7da6e4ca5af209cf12feea1b95b9a19d46f242a3'
       }
       this.changeState =this.changeState.bind(this); 
       this.getBase64url = this.getBase64url.bind(this);
       this.handleFileInputChange = this.handleFileInputChange.bind(this);
       this.handleEmailChange = this.handleEmailChange.bind(this);
       this.getData();

     }

     handleEmailChange({target})
     {
      this.setState({
        reciever: target.value,
      });
    }

     getBase64url(file){
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result)
        };
        reader.onerror = error => reject(error);
      });
     }

     handleFileInputChange(e){
      // console.log(e.target.files[0]);
    let file = e.target.files[0];

    this.getBase64url(file)
      .then(result => {
        result = '@'.concat(result)
        result = file.name.concat(result)
        // file.base64 = result;
        // console.log(result);
        // console.log("File Is", file);
        this.setState({dataString:result})
      })
      .catch(err => {
        console.log(err);
      });

    this.setState({
      file: e.target.files[0]
    });
     }

     json(url) {
       return fetch(url).then(res => res.json());
     }

  getData = () =>{

       fetch(`${process.env.REACT_APP_BASE_URL}data/getData`, {
         method: 'GET',
         mode: "cors",
         headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'x-access-token':localStorage.getItem("x-access-token"),
            'access-control-allow-credentials': true,
            'access-control-allow-headers': 'Api-Version,DNT,X-CustomHeader,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,X-Access-Token,device-id,tutorWebsiteDomain,region,accept-language,X-Forwarded-For,x-chrome-version,x-webview-version,zAccessToken,zRefreshToken,Authorization',
            'access-control-allow-methods': 'POST',
            'Access-Control-Allow-Origin': '*',
            'access-control-max-age': 1728000,

         },
      }).then(response => response.json())
      .then((response) => {
         //  console.log("ddddddddddddddd",response.status)
           if(response.status == 'success')
           {
            //  toast.success(response.message);
             localStorage.setItem("sentData",JSON.stringify(response.data[0].sendData)||[]);
             localStorage.setItem("recievedData",JSON.stringify(response.data[0].recieveData)||[]);

           }
           else
           {
            //  toast.warn(response.message);
           } 
           
     })
  }

  handleSubmit = () => {
        this.json(`https://api.ipdata.co?api-key=${process.env.REACT_APP_IP_API_KEY}`).then(data => {
      //  console.log(data.ip);
      //  console.log(data.city);
      //  console.log(data.country_code);
       // so many more properties
    

       
       var raw = JSON.stringify({
         "name": localStorage.getItem('profileName'),
         "email":this.state.reciever,
         "data":this.state.dataString,
         userIp:data.ip,
        //  userData: data,
       });
      //  alert(raw)
          fetch(`${process.env.REACT_APP_BASE_URL}data/sendData`, {
            method: 'POST',
            mode: "cors",
            body: raw,
            headers: {
               'Content-type': 'application/json; charset=UTF-8',
               'x-access-token':localStorage.getItem("x-access-token"),
               'access-control-allow-credentials': true,
               'access-control-allow-headers': 'Api-Version,DNT,X-CustomHeader,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,X-Access-Token,device-id,tutorWebsiteDomain,region,accept-language,X-Forwarded-For,x-chrome-version,x-webview-version,zAccessToken,zRefreshToken,Authorization',
               'access-control-allow-methods': 'POST',
               'Access-Control-Allow-Origin': '*',
               'access-control-max-age': 1728000,
 
            },
         }).then(response => response.json())
         .then((response) => {
            //  console.log("ddddddddddddddd",response.status)
              if(response.status == 'success')
              {
                toast.success(response.message);
                this.getData()
                window.location.reload()
              }
              else
              {
                toast.warn(response.message);
              } 
              this.setState({
                isLogedin: true,
                topicBox:'',
                isLoading :false
               });
              
        })

         })
         .catch((error) => {
          console.log(error)
          this.setState({
            isLogedin: false,
            queryString:'',
        })

        });
     };
     changeState(e)
     {    
         if (this.state.isLogedin) {
             this.setState({
                 isLogedin: false,
                 queryString:'',
             })
             
         } else {
            // console.log("calling");
            this.setState({
              isLoading : true
            });
            this.handleSubmit()
            // this.setState({
            //     isLogedin: true,
            //     topicBox:'',
            // })
         }
     }
  getFile = (e) =>{

    // alert(e.target.id)
      fetch(`${process.env.REACT_APP_BASE_URL}data/Download/?id=${e.target.id}`, {
        method: 'GET',
        mode: "cors",
        // body:{id:e.target.id},
        headers: {
           'Content-type': 'application/json; charset=UTF-8',
           'x-access-token':localStorage.getItem("x-access-token"),
           'access-control-allow-credentials': true,
           'access-control-allow-headers': 'Api-Version,DNT,X-CustomHeader,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,X-Access-Token,device-id,tutorWebsiteDomain,region,accept-language,X-Forwarded-For,x-chrome-version,x-webview-version,zAccessToken,zRefreshToken,Authorization',
           'access-control-allow-methods': 'POST',
           'Access-Control-Allow-Origin': '*',
           'access-control-max-age': 1728000,

        },
     }).then(response => response.json())
     .then((response) => {
        //  console.log("ddddddddddddddd",response.status)
          if(response.status == 'success')
          {
            // new File(response.data[0].downloadData,response.data[0].name)
            let reader = new FileReader();
            // reader.readAsDataURL(response.data[0].downloadData)
            const downloadLink = document.createElement("a");
            downloadLink.href = response.data[0].downloadData;
            downloadLink.download = response.data[0].name;
            downloadLink.click();
            toast.success(response.message);
          }
          else
          {
            toast.warn(response.message);
          } 
          
    })
 }
  render() {
    if (this.state.isLoading) {
      return(
          <div>
            <SpinnerInfinity className='spinner' enabled={(this.state.isLoading === true)? true:false} size = '20%' thickness={50}/>
          </div>

      )
  }
   else {
          return(
            <div id="SearchDiv" style={{  }}>
              <Card id ="SearchCard" style={{  }}>
                <Card.Body>
                  <Card.Title >Welcome {localStorage.getItem('profileName')}</Card.Title>
                  {/* <textarea id = "queryString" value ={ this.state.topicBox } onChange={ this.handleChange }></textarea><br></br> */}
                  <label>Enter the receiver Email</label>
                  <input type="text" id='emailInput' name='email' onChange={this.handleEmailChange}/>
                  <label>Select The File to Send</label>
                  <input type="file" name="file" onChange={this.handleFileInputChange} /><br/>
                  <Button onClick={this.changeState} variant="outline-primary" size="lg">Send</Button>
                  </Card.Body>
              </Card>
              <div className='Send'>
                <h2>Sent</h2><br/>
                <br/>
                    {/* {console.log(localStorage.getItem('sentData'))} */}
                    {
                      JSON.parse(localStorage.getItem('sentData')).map((val, key) => {
                            // alert(val.name  )
                            return (
                                    <>
                                      <h4>Sent To :&nbsp;{val.to}</h4>
                                      <h4>File Name :&nbsp;{val.dataUrl}</h4>
                                      <br/>
                                      <Example fileid = {val.id} name = {val.dataUrl} getFile = {this.getFile}/>
                                      
                                    </>
                                    )
                                })}
              </div>
              <div className='Send'>
                <h2>Recived</h2><br/>
                <br/>
                    {/* {console.log(localStorage.getItem('sentData'))} */}
                    {
                      JSON.parse(localStorage.getItem('recievedData')).map((val, key) => {
                            // alert(val.name  )
                            return (
                                    <>
                                      <h4>Recived :&nbsp;{val.from}</h4>
                                      <h4>File Name :&nbsp;{val.dataUrl}</h4>
                                      {/* <Button onClick={this.getFile}><h4 id={val.id}>Click Download</h4></Button><br/> */}
                                      {/* <PDFViewer fileid = {val.id}/> */}
                                      <Example fileid = {val.id} name = {val.dataUrl} getFile = {this.getFile}/>
                                    </>
                                    )
                                })}
              </div>
              <ToastContainer/>
        </div>
          )
      }
  }
}
export default DataSharing


{/* <InfinitySpin 
  width='200'
  color="#4fa94d"
/> */}