import React, { Component } from 'react'
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import { ToggleButton } from 'react-bootstrap';
// import { BASE_URL } from '../constant';
 class UserAccessControl extends Component {
     constructor(props) {
       super(props)
     
       this.state = {
          isLogedin :false,
          isLoading: false,
          message: [],
          queryString :'',
          topicBox:'',
          data:[],
          checkbox:[]
          // apiKey:'3f80deeb8d777bcf7da6e4ca5af209cf12feea1b95b9a19d46f242a3'
       }
       this.setData = this.setData.bind(this);
       this.handleChange = this.handleChange.bind(this);
      //  this.getUserList = this.getUserList.bind(this);

     }
     setData = () =>{
      // this.setState({
      //   data:JSON.parse(localStorage.getItem("usersData"))
      // });
        let checkboxArray = [];
        JSON.parse(localStorage.getItem("usersData")).map((val,key) =>{
         checkboxArray.push({id:val.id,checked:(val.visibility === 1)? true:false})
         

     })
     localStorage.setItem("checkbox",JSON.stringify(checkboxArray));
     

}

handleChange = (e) =>{
    // alert(e.target.id)
    var index = JSON.parse(localStorage.getItem('checkbox')).findIndex(p => p.id == e.target.id);
    var checked = (JSON.parse(localStorage.getItem('checkbox'))[index].checked)? 0:1;
    // alert(e.target.id);
    var requestOptions = {
        method: 'POST',
        headers: 
        {
         "Content-Type": "application/json",
         "x-access-token":localStorage.getItem("x-access-token")
        },
        body:JSON.stringify({
            "id": JSON.parse(localStorage.getItem('checkbox'))[index].id,
            "visibility":checked
        
        }),
        redirect: 'follow'
      };
    fetch(`${process.env.REACT_APP_BASE_URL}user/updateUserAccess`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status === 'success')
     {
        // alert("getting user data")
        this.props.getUserList()
        // this.props.ge
     }
     setTimeout(function() {
      
      window.location.reload()
    
    }, 1000);
  })
  .catch(error => console.log('error', error));
    
}

// getUserList = () =>{
          
//     var requestOptions = {
//       method: 'GET',
//       headers: 
//       {
//        "Content-Type": "application/json",
//        "x-access-token":localStorage.getItem("x-access-token")
//       },
//       redirect: 'follow'
//     };

//     fetch(`${process.env.REACT_APP_BASE_URL}user/getUsers`, requestOptions)
// .then(response => response.json())
// .then(result => {
// // this.setState({data:result.data[0].usersData});
// localStorage.setItem("usersData",JSON.stringify(result.data[0].usersData));

// if(result.status === 'success')
//      {
//       // alert(JSON.stringify(result1.data[0].name))
//      }
//      setTimeout(function() {
      
//       window.location.reload()
    
//     }, 1000);
// // console.log(result)

// })
// .catch(error => console.log('error', error));
// } 
     
  render() {
      return(
        <div className="UsersDiv">
            {this.setData()}
        <table>
          <thead>
          <tr>
            <th><h3>User id</h3></th>
            <th><h3>Name</h3></th>
            <th><h3>email</h3></th>
            <th><h3>visibility</h3></th>
          </tr>
          </thead>
          <tbody>
         {
          JSON.parse(localStorage.getItem("usersData")).map((val, key) => {
            // alert(val.name  )
            return (
              <tr key={key}>
                <td><h4>{val.id}</h4></td>
                <td><h4>{val.name}</h4></td>
                <td><h4>{val.email}</h4></td>
                <td><input type='checkbox' id={val.id} onChange={this.handleChange} checked={JSON.parse(localStorage.getItem('checkbox'))[key].checked}></input></td>
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>

      );
    }
}
export default UserAccessControl
